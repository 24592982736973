import {Component, HostBinding, OnInit} from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';
import {NavigationError, Router, RouterModule} from '@angular/router';
import {SideNavComponent} from './side-nav.component';
import {TopNavComponent} from './top-nav.component';
import {BehaviorSubject, map} from 'rxjs';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {CommonModule} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {VersionReloadComponent} from './version-reload.component';
import {filter} from 'rxjs/operators';
import {CanaryComponent} from './common/canary.component';
import {AppState} from './common/app-state';
import {NavigationService} from './common/navigation.service';

const DARK_MODE_CLASS = 'dark-mode';

@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="!(appState.helpMode$|async); else helpMode">
      <div [class.hide]="!(loader.value$|async)" class="loader-spinner">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
      </div>
      <app-top-nav [sidenav]="sidenav"></app-top-nav>
      <app-side-nav #sidenav>
        <router-outlet></router-outlet>
      </app-side-nav>
    </ng-container>

    <ng-template #helpMode>
      <router-outlet></router-outlet>
    </ng-template>

    <version-reload></version-reload>
    <app-canary></app-canary>
  `,
  standalone: true,
  imports: [
    CommonModule, RouterModule, TopNavComponent, SideNavComponent, MatProgressSpinnerModule, VersionReloadComponent,
    CanaryComponent,
  ]
})
export class AppComponent implements OnInit {
  @HostBinding('class') className = '';
  darkmode = new BehaviorSubject(false);

  // noinspection JSUnusedGlobalSymbols
  constructor(private overlay: OverlayContainer, public loader: LoadingBarService, private router: Router,
              public appState: AppState, public navigation: NavigationService) {
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(evt => evt instanceof NavigationError && evt.error instanceof Error && evt.error.name == 'ChunkLoadError'),
        map(evt => evt as NavigationError),
      )
      .subscribe(evt => window.location.assign(evt.url));

    this.darkmode.subscribe((darkMode) => {
      this.className = darkMode ? DARK_MODE_CLASS : '';
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(DARK_MODE_CLASS);
      } else {
        this.overlay.getContainerElement().classList.remove(DARK_MODE_CLASS);
      }
    });

    const {hostname, pathname} = window.location;
    if (hostname !== 'localhost' && pathname !== '/help') {
      const node = document.createElement('script');
      node.src = '//js.hs-scripts.com/144551991.js';
      node.async = true;
      node.id = 'hs-script-loader';
      node.defer = true;
      document.body.appendChild(node);
    }
  }
}
