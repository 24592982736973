<mat-sidenav-container class="app-container">
  <mat-sidenav #sidenav [class.mat-elevation-z4]="true">
    <mat-nav-list *ngIf="company && site; else noCompany">

      <mat-list-item [class.activated]="isDashboard" [routerLink]="['/company',company.id,'site',site.id,'dashboard']">
        Dashboard
      </mat-list-item>
      <mat-list-item [routerLink]="['/company',company.id,'site',site.id,'chain']" routerLinkActive="activated">
        <div class="circle-number">1</div>
        Supply Chain Builder
      </mat-list-item>
      <mat-list-item [routerLink]="['/company',company.id,'site',site.id,'evaluation']" routerLinkActive="activated">
        <div class="circle-number">2</div>
        Active Reviews
      </mat-list-item>
      <mat-list-item [routerLink]="['/company',company.id,'site',site.id,'archive']" routerLinkActive="activated">
        <div class="circle-number">3</div>
        Report Archive
      </mat-list-item>
    </mat-nav-list>
    <ng-template #noCompany>
      <mat-nav-list>
        <a class="back">Main</a>
        <mat-list-item [routerLink]="['dashboard']" routerLinkActive="activated">
          Dashboard
        </mat-list-item>
        <a class="back">Select Company</a>
        <ng-container *ngFor="let c of companies">
          <mat-list-item *ngFor="let s of c.site" [routerLink]="['/company',c.id,'site',s.id,'dashboard']">
            <div style="display: flex; gap:10px; align-items: center">
              <mat-icon style="opacity: 0.7;">apartment</mat-icon>
              {{ c.name }} - {{ s.name }}
            </div>
          </mat-list-item>
        </ng-container>
      </mat-nav-list>
    </ng-template>
  </mat-sidenav>

  <mat-sidenav-content>
    <main [class.wide]="wide">
      <ng-content></ng-content>
    </main>
  </mat-sidenav-content>

</mat-sidenav-container>
